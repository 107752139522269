<template>
  <div id="app">
    <el-row>
      <el-col :span="19">
        <gmap-map
          :center="center"
          :zoom="14"
          :options="options"
          :draggable="false"
          style="width: 98%;  height: 95vh;"
        >
          <gmap-marker
            :position="points"
            :title="points.lat + ' ' + points.lng"
            :draggable="true"
            @position_changed="updatePlaceM1($event)"
          >
          </gmap-marker>
        </gmap-map>
      </el-col>
      <el-col :span="5">
        <el-button @click="search" type="success">Хайх</el-button>
        <!-- <el-button @click = "search1" type="success">Хайх1</el-button> -->
        <!-- {{addresses[0]}} -->
        <div
          class="searchResult"
          v-for="address in addresses"
          :key="address.id"
        >
          <el-row style="text-align: left;">
            <!-- <li style="font-size: 12px; font-weight: bolder">{{
              address.full_address
            }}</li> -->
            <ul>
              <li @click="handleClick(address)" style="cursor: pointer">
                {{ address.city }} - {{ address.full_address }}
              </li>
            </ul>
          </el-row>
        </div>
      </el-col>
    </el-row>
    <address-dialog
      :addressData="this.addressData"
      :type="this.type"
      :showAddressDialog="showAddressDialog"
      :closeFunction="closeDialog"
    ></address-dialog>
  </div>
</template>

<script>
// import { getAddressNearby1 } from '../helper'
// import { getAddressNearby } from '../helper/request'
import services from "../helpers/services.js";
import AddressDialog from "./AddressDialog";
export default {
  created() {},
  components: {
    AddressDialog
  },
  data() {
    return {
      addresses: [],
      newPod: false,
      newPodObj: {},
      brands: [],
      brand: "",
      outlets: [],
      outlet: "",
      paths: [],
      selectedPod: {},
      options: {
        scrollwheel: true,
        mapTypeControl: true,
        streetViewControl: false,
        minZoom: 4,
        maxZoom: 20,
        gestureHandling: "greedy"
      },
      center: {
        lat: 47.9174,
        lng: 106.9137
      },
      points: { lat: 47.9174, lng: 106.9137 },
      optionsPoly: {
        fillColor: "#000000",
        strokeColor: "#FF0000",
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      optionsPoly1: {
        fillColor: "#000000",
        strokeColor: "#00FF00",
        strokeOpacity: 0.9,
        fillOpacity: 0.1
      },
      addressData: null,
      showAddressDialog: false,
      type: false
    };
  },
  mounted() {},
  methods: {
    search() {
      services.getAddressNearby(this.points.lat, this.points.lng).then(data => {
        this.addresses = data;
      });
    },
    search1() {
      services.getAddressNearby(this.points.lat, this.points.lng).then(data => {
        this.addresses = data;
      });
    },
    updatePlaceM1(path) {
      this.points.lng = path.lng();
      this.points.lat = path.lat();
    },
    handleClick(data) {
      this.addressData = JSON.parse(JSON.stringify(data));
      this.showAddressDialog = true;
    },
    closeDialog() {
      this.addressData = null;
      this.showAddressDialog = false;
    }
  }
};
</script>
<style scoped>
.searchResult ul {
  border: solid 2px #89b23b;
  border-radius: 5px;
  padding: 0.5em 1em 0.5em 2.3em;
  position: relative;
  font-weight: bold;
  color: gray;
  font-size: 10px;
}
.searchResult li {
  line-height: 1;
  padding: 0.5em 0;
}
</style>
